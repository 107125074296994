<template lang="pug">
modal(size='sm' @close='$emit("close")') 
  generic-form#FormInquiryRequestWhenExpired(:endpoint='`/_/Inquiry/${inquiryId}`' hideNotifications @submitSuccess='$emit("close")')
    template(#default='{ configMap }')
      form-header {{ $t('info.inquiryRequest.title') }}
        template(#below)
          p(v-html='$t("info.inquiryRequest.content")')
      //- hide DesiredDeliveryDate when no value provided
      //- when no value was provided "standardDelivery" was 
      //- set as "DeliverySetupTypes"
      template(v-if='configMap["DesiredDeliveryDate"].value')
        form-input-date(name='DesiredDeliveryDate' :autofocus='true')
      form-footer
        form-action(:primary='true' name='RequestWhenExpired' :label='$t("info.inquiryRequest.okText")')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormInquiryRequestWhenExpired = defineComponent({
  inheritAttrs: false,
  props: {
    inquiryId: { type: String, required: true },
  },
})
export default FormInquiryRequestWhenExpired
</script>
